import type { LocaleObject } from '@/types/locale';

export const cmsLivePreviewLanguageMappings: { [key: string]: string } = {
  de: 'de',
  en: 'en',
};

export const localeList: LocaleObject[] = [
  {
    code: 'en',
    cmsFallbackLanguage: 'default',
    lang: 'en',
    alpha3: 'ENG',
  },
  {
    code: 'de',
    cmsFallbackLanguage: 'en',
    lang: 'de',
    alpha3: 'DEU',
  },
];

export const COUNTRY_MAP: { [key: string]: string } = {
  29: 'DE',
  153: 'PL',
  74: 'ES',
  161: 'RO',
  103: 'IT',
  40: 'BE',
  91: 'NL',
  79: 'FR',
  160: 'CZ',
  175: 'SE',
};

export const LOCALE_MAP: { [key: string]: string } = {
  29: 'de',
  35: 'de', // austria
  176: 'de', // switzerland
  153: 'pl-pl',
  74: 'es',
  161: 'en-ro',
  103: 'it',
  40: 'fr', // belgium
  91: 'nl',
  79: 'fr', // france
  160: 'cs-cz',
  175: 'en-se', // sweden
};

export const defaultLocale: string = localeList[0].code;
