import type { CmsLink } from '@mop/cms/types';
import { getLink } from '@mop/cms/utils/utils';
import type { NavigationModel } from '@/types/navigationModel';

export function navigationModel(data: any) {
  return {
    getLink() {
      return getLink(data.link);
    },
    getSlug() {
      const link: CmsLink = getLink(data.link);
      return link.to || link.href || link.url || '';
    },
    getName() {
      return data.name;
    },
    hasChildren() {
      return data?.children?.length > 0;
    },
    getChildren(): NavigationModel[] {
      if (!data?.children?.length) {
        return [];
      }
      return data.children.map((item: any) => navigationModel(item));
    },
    getUid() {
      return data._uid;
    },

    getData() {
      return data;
    },
  };
}

export function navigationListModel(responseData: any | null) {
  return {
    getList(): NavigationModel[] {
      return responseData?.map((item: any) => navigationModel(item)) ?? [];
    },
  };
}
