import type { Timer } from '@mop/types';

export function isNumber(value: string) {
  return /^\d+$/.test(value);
}

export function removeEmptyHtmlParagraphs(html: string) {
  return html ? html.replace(/<p>&nbsp;<\/p>/gi, '') : html;
}

type SocialMediaMetaProperties = {
  twitterCard: string;
  ogType: string;
  url: string;
  title: string;
  image: string;
  description: string;
};

type SocialMediaTag = {
  property?: string;
  name?: string;
  content: string;
};

export function createSocialMediaTags(options: SocialMediaMetaProperties) {
  type metaKeys = keyof typeof options;

  const tags: SocialMediaTag[] = Object.keys(options).map((option) => ({
    name: `twitter:${option}`,
    property: `og:${option}`,
    content: options[option as metaKeys],
  }));

  return [
    ...tags,
    {
      name: 'twitter:card',
      content: options.twitterCard,
    },
    {
      name: 'og:type',
      content: options.ogType,
    },
  ];
}

export function isTouchDevice() {
  return (
    'ontouchstart' in window ||
    navigator.maxTouchPoints > 0 ||
    // @ts-ignore
    navigator.msMaxTouchPoints > 0
  );
}

export function isLinux() {
  return /X11; Linux/i.test(navigator?.userAgent);
}

export function isPrerender() {
  return navigator?.userAgent?.toLowerCase().includes('prerender');
}

export function isLoading(
  state: Ref<{
    [key: string]: boolean;
  }>,
) {
  return Object.keys(state.value).some((key: string) => key !== 'loading' && state.value[key] === true);
}

export const isHttpsEnabled: boolean = (window as any).__NUXT__.config.public.BASE_URL.startsWith('https://');

export const isProductionBuild: boolean =
  isHttpsEnabled &&
  (window as any).__NUXT__.config.public.RUNTIME_ENVIRONMENT === constants.RUNTIME_ENVIRONMENT.PRODUCTION;

export const defaultStoryblokLanguage: string = (window as any).__NUXT__.config.public.DEFAULT_STORYBLOK_LANGUAGE;

export function throttle(callback: (...args: any[]) => void, wait = 200) {
  let timer: Timer;
  let currentTime: number;

  return function throttledCallback(...args: unknown[]) {
    if (!currentTime) {
      // eslint-disable-next-line no-useless-call
      callback.call(null, ...args);
      currentTime = Date.now();
      return;
    }

    clearTimeout(timer);
    timer = setTimeout(
      function debouncedCall() {
        if (Date.now() - currentTime >= wait) {
          // eslint-disable-next-line no-useless-call
          callback.call(null, ...args);
          currentTime = Date.now();
        }
      },
      wait - (Date.now() - currentTime),
    );
  };
}

export function removeValuesFromArray(
  array: Array<number | string | boolean>,
  ...values: Array<number | string | boolean>
) {
  if (!Array.isArray(array) || array.length === 0) {
    return;
  }
  values.forEach((value) => {
    const index: number = array.indexOf(value);
    if (index !== -1) {
      array.splice(index, 1);
    }
  });
}

export function removeValuesFromArrayRef(
  list: Ref<Array<number | string | boolean>>,
  ...values: Array<number | string | boolean>
) {
  removeValuesFromArray(list.value, ...values);
}

export function addValuesToArrayRef(
  list: Ref<Array<number | string | boolean>>,
  ...values: Array<number | string | boolean>
) {
  addValuesToArray(list.value, ...values);
}

export function addValuesToArray(array: Array<number | string | boolean>, ...values: Array<number | string | boolean>) {
  if (!Array.isArray(array)) {
    return;
  }
  values.forEach((value) => {
    const index: number = array.indexOf(value);
    if (index === -1) {
      array.push(value);
    }
  });
}
