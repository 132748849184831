import { default as indexbZRUGsqmqKMeta } from "/opt/build/repo/apps/company/pages/index.vue?macro=true";
import { default as HomeU1vJlFwouUMeta } from "/views/index/Home.vue?macro=true";
import { default as JobSearchfqvIHb7Qu2Meta } from "/views/career/JobSearch.vue?macro=true";
import { default as ThankYoudvsWTcZ7CLMeta } from "/views/career/ThankYou.vue?macro=true";
import { default as JobApplyR9JQxO3vGMMeta } from "/views/career/JobApply.vue?macro=true";
import { default as JobPostpJjaVPL7KGMeta } from "/views/career/JobPost.vue?macro=true";
import { default as JobRedirectzP9q5q35kdMeta } from "/views/career/JobRedirect.vue?macro=true";
import { default as SearchweWMii1ze3Meta } from "/views/search/Search.vue?macro=true";
import { default as WishlistxS6mD6PsuMMeta } from "/views/wishlist/Wishlist.vue?macro=true";
import { default as ShowRoomfTb8rUYW5UMeta } from "/views/showroom/ShowRoom.vue?macro=true";
import { default as ContactRO9cQ0e41yMeta } from "/views/contact/Contact.vue?macro=true";
import { default as NewsDetailXXCtV3D0f7Meta } from "/views/news/NewsDetail.vue?macro=true";
import { default as NewsOverviewOUnMt04AldMeta } from "/views/news/NewsOverview.vue?macro=true";
import { default as NewsnN9wApULzHMeta } from "/views/news/News.vue?macro=true";
import { default as BlogOverviewDxXAaAhOtFMeta } from "/views/blog/BlogOverview.vue?macro=true";
import { default as Blog3L73v8TKD8Meta } from "/views/blog/Blog.vue?macro=true";
import { default as BlogDetailbPRxPBZWg7Meta } from "/views/blog/BlogDetail.vue?macro=true";
import { default as Content6w4NcPiXO3Meta } from "/views/content/Content.vue?macro=true";
export default [
  {
    name: indexbZRUGsqmqKMeta?.name ?? "index",
    path: indexbZRUGsqmqKMeta?.path ?? "/",
    meta: indexbZRUGsqmqKMeta || {},
    alias: indexbZRUGsqmqKMeta?.alias || [],
    redirect: indexbZRUGsqmqKMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/apps/company/pages/index.vue").then(m => m.default || m)
  },
  {
    name: HomeU1vJlFwouUMeta?.name ?? "home",
    path: HomeU1vJlFwouUMeta?.path ?? "/:lang(en|de)/:theme(theme-company)?/:global(global)?",
    meta: {...(HomeU1vJlFwouUMeta || {}), ...{"cacheMaxAgeInSeconds":86400}},
    alias: HomeU1vJlFwouUMeta?.alias || [],
    redirect: HomeU1vJlFwouUMeta?.redirect || undefined,
    component: () => import("/views/index/Home.vue").then(m => m.default || m)
  },
  {
    name: JobSearchfqvIHb7Qu2Meta?.name ?? "career-search",
    path: JobSearchfqvIHb7Qu2Meta?.path ?? "/en/:theme(theme-company)?/career/start-creating-with-us/our-jobs",
    alias: ["/de/:theme(theme-company)?/karriere/start-creating-with-us/unsere-jobs","/en/:theme(theme-company)?/career/start-creating-with-us/our-jobs","/en/:theme(theme-company)?/karriere/start-creating-with-us/unsere-jobs","/de/:theme(theme-company)?/career/start-creating-with-us/our-jobs","/de/:theme(theme-company)?/karriere/start-creating-with-us/unsere-jobs"].concat(JobSearchfqvIHb7Qu2Meta?.alias || []),
    meta: {...(JobSearchfqvIHb7Qu2Meta || {}), ...{"cacheMaxAgeInSeconds":86400,"hideJobsStickyButton":true}},
    redirect: JobSearchfqvIHb7Qu2Meta?.redirect || undefined,
    component: () => import("/views/career/JobSearch.vue").then(m => m.default || m)
  },
  {
    name: ThankYoudvsWTcZ7CLMeta?.name ?? "career-job-thank-you",
    path: ThankYoudvsWTcZ7CLMeta?.path ?? "/en/:theme(theme-company)?/career/start-creating-with-us/our-jobs/thank-you",
    alias: ["/de/:theme(theme-company)?/karriere/start-creating-with-us/unsere-jobs/thank-you","/en/:theme(theme-company)?/career/start-creating-with-us/our-jobs/thank-you","/en/:theme(theme-company)?/karriere/start-creating-with-us/unsere-jobs/thank-you","/de/:theme(theme-company)?/career/start-creating-with-us/our-jobs/thank-you","/de/:theme(theme-company)?/karriere/start-creating-with-us/unsere-jobs/thank-you"].concat(ThankYoudvsWTcZ7CLMeta?.alias || []),
    meta: {...(ThankYoudvsWTcZ7CLMeta || {}), ...{"cacheMaxAgeInSeconds":86400,"hideJobsStickyButton":true}},
    redirect: ThankYoudvsWTcZ7CLMeta?.redirect || undefined,
    component: () => import("/views/career/ThankYou.vue").then(m => m.default || m)
  },
  {
    name: JobApplyR9JQxO3vGMMeta?.name ?? "career-job-apply",
    path: JobApplyR9JQxO3vGMMeta?.path ?? "/en/:theme(theme-company)?/career/start-creating-with-us/our-jobs/apply/:jobId(.*)",
    alias: ["/de/:theme(theme-company)?/karriere/start-creating-with-us/unsere-jobs/apply/:jobId(.*)","/en/:theme(theme-company)?/career/start-creating-with-us/our-jobs/apply/:jobId(.*)","/en/:theme(theme-company)?/karriere/start-creating-with-us/unsere-jobs/apply/:jobId(.*)","/de/:theme(theme-company)?/career/start-creating-with-us/our-jobs/apply/:jobId(.*)","/de/:theme(theme-company)?/karriere/start-creating-with-us/unsere-jobs/apply/:jobId(.*)"].concat(JobApplyR9JQxO3vGMMeta?.alias || []),
    meta: {...(JobApplyR9JQxO3vGMMeta || {}), ...{"cacheMaxAgeInSeconds":86400,"hideJobsStickyButton":true}},
    redirect: JobApplyR9JQxO3vGMMeta?.redirect || undefined,
    component: () => import("/views/career/JobApply.vue").then(m => m.default || m)
  },
  {
    name: JobPostpJjaVPL7KGMeta?.name ?? "career-job-post",
    path: JobPostpJjaVPL7KGMeta?.path ?? "/en/:theme(theme-company)?/career/start-creating-with-us/our-jobs/:jobId(.*)",
    alias: ["/de/:theme(theme-company)?/karriere/start-creating-with-us/unsere-jobs/:jobId(.*)","/en/:theme(theme-company)?/career/start-creating-with-us/our-jobs/:jobId(.*)","/en/:theme(theme-company)?/karriere/start-creating-with-us/unsere-jobs/:jobId(.*)","/de/:theme(theme-company)?/career/start-creating-with-us/our-jobs/:jobId(.*)","/de/:theme(theme-company)?/karriere/start-creating-with-us/unsere-jobs/:jobId(.*)"].concat(JobPostpJjaVPL7KGMeta?.alias || []),
    meta: {...(JobPostpJjaVPL7KGMeta || {}), ...{"cacheMaxAgeInSeconds":86400,"hideJobsStickyButton":true}},
    redirect: JobPostpJjaVPL7KGMeta?.redirect || undefined,
    component: () => import("/views/career/JobPost.vue").then(m => m.default || m)
  },
  {
    name: JobRedirectzP9q5q35kdMeta?.name ?? "job-redirect",
    path: JobRedirectzP9q5q35kdMeta?.path ?? "/job/:jobId(.*)",
    alias: ["/(en|de)/job/:jobId(.*)"].concat(JobRedirectzP9q5q35kdMeta?.alias || []),
    meta: {...(JobRedirectzP9q5q35kdMeta || {}), ...{"cacheMaxAgeInSeconds":86400}},
    redirect: JobRedirectzP9q5q35kdMeta?.redirect || undefined,
    component: () => import("/views/career/JobRedirect.vue").then(m => m.default || m)
  },
  {
    name: SearchweWMii1ze3Meta?.name ?? "search-page",
    path: SearchweWMii1ze3Meta?.path ?? "/:lang(en|de)/:theme(theme-company)?/search",
    meta: {...(SearchweWMii1ze3Meta || {}), ...{"cacheMaxAgeInSeconds":86400,"hideJobsStickyButton":true}},
    alias: SearchweWMii1ze3Meta?.alias || [],
    redirect: SearchweWMii1ze3Meta?.redirect || undefined,
    component: () => import("/views/search/Search.vue").then(m => m.default || m)
  },
  {
    name: WishlistxS6mD6PsuMMeta?.name ?? "wishlist-page",
    path: WishlistxS6mD6PsuMMeta?.path ?? "/:lang(en|de)/:theme(theme-company)?/wishlist",
    meta: {...(WishlistxS6mD6PsuMMeta || {}), ...{"cacheMaxAgeInSeconds":86400}},
    alias: WishlistxS6mD6PsuMMeta?.alias || [],
    redirect: WishlistxS6mD6PsuMMeta?.redirect || undefined,
    component: () => import("/views/wishlist/Wishlist.vue").then(m => m.default || m)
  },
  {
    name: ShowRoomfTb8rUYW5UMeta?.name ?? "showroom-page",
    path: ShowRoomfTb8rUYW5UMeta?.path ?? "/en/:theme(theme-company)?/company/facts-figures",
    alias: ["/de/:theme(theme-company)?/unternehmen/facts-figures","/en/:theme(theme-company)?/company/facts-figures","/en/:theme(theme-company)?/unternehmen/facts-figures","/de/:theme(theme-company)?/company/facts-figures","/de/:theme(theme-company)?/unternehmen/facts-figures"].concat(ShowRoomfTb8rUYW5UMeta?.alias || []),
    meta: {...(ShowRoomfTb8rUYW5UMeta || {}), ...{"cacheMaxAgeInSeconds":86400}},
    redirect: ShowRoomfTb8rUYW5UMeta?.redirect || undefined,
    component: () => import("/views/showroom/ShowRoom.vue").then(m => m.default || m)
  },
  {
    name: ContactRO9cQ0e41yMeta?.name ?? "contact",
    path: ContactRO9cQ0e41yMeta?.path ?? "/en/:theme(theme-company)?/contact",
    alias: ["/de/:theme(theme-company)?/kontakt","/en/:theme(theme-company)?/contact","/en/:theme(theme-company)?/kontakt","/de/:theme(theme-company)?/contact","/de/:theme(theme-company)?/kontakt"].concat(ContactRO9cQ0e41yMeta?.alias || []),
    meta: {...(ContactRO9cQ0e41yMeta || {}), ...{"cacheMaxAgeInSeconds":86400,"hideJobsStickyButton":true}},
    redirect: ContactRO9cQ0e41yMeta?.redirect || undefined,
    component: () => import("/views/contact/Contact.vue").then(m => m.default || m)
  },
  {
    name: NewsDetailXXCtV3D0f7Meta?.name ?? "news-detail",
    path: NewsDetailXXCtV3D0f7Meta?.path ?? "/:lang(en|de)/:theme(theme-company)?/news/detail/:path(.*)",
    meta: {...(NewsDetailXXCtV3D0f7Meta || {}), ...{"cacheMaxAgeInSeconds":86400}},
    alias: NewsDetailXXCtV3D0f7Meta?.alias || [],
    redirect: NewsDetailXXCtV3D0f7Meta?.redirect || undefined,
    component: () => import("/views/news/NewsDetail.vue").then(m => m.default || m)
  },
  {
    name: NewsnN9wApULzHMeta?.name ?? "news",
    path: NewsnN9wApULzHMeta?.path ?? "/:lang(en|de)/:theme(theme-company)?/news",
    children: [
  {
    name: NewsOverviewOUnMt04AldMeta?.name ?? "news-category",
    path: NewsOverviewOUnMt04AldMeta?.path ?? "/:lang(en|de)/:theme(theme-company)?/news/:category?",
    meta: {...(NewsOverviewOUnMt04AldMeta || {}), ...{"cacheMaxAgeInSeconds":86400}},
    alias: NewsOverviewOUnMt04AldMeta?.alias || [],
    redirect: NewsOverviewOUnMt04AldMeta?.redirect || undefined,
    component: () => import("/views/news/NewsOverview.vue").then(m => m.default || m)
  }
],
    meta: {...(NewsnN9wApULzHMeta || {}), ...{"cacheMaxAgeInSeconds":86400}},
    alias: NewsnN9wApULzHMeta?.alias || [],
    redirect: NewsnN9wApULzHMeta?.redirect || undefined,
    component: () => import("/views/news/News.vue").then(m => m.default || m)
  },
  {
    name: Blog3L73v8TKD8Meta?.name ?? "blog",
    path: Blog3L73v8TKD8Meta?.path ?? "/en/:theme(theme-company)?/career/career-blog",
    alias: ["/de/:theme(theme-company)?/karriere/karriere-blog","/en/:theme(theme-company)?/career/career-blog","/en/:theme(theme-company)?/karriere/karriere-blog","/de/:theme(theme-company)?/career/career-blog","/de/:theme(theme-company)?/karriere/karriere-blog"].concat(Blog3L73v8TKD8Meta?.alias || []),
    children: [
  {
    name: BlogOverviewDxXAaAhOtFMeta?.name ?? "blog-category",
    path: BlogOverviewDxXAaAhOtFMeta?.path ?? "/en/:theme(theme-company)?/career/career-blog/:category?",
    alias: ["/de/:theme(theme-company)?/karriere/karriere-blog/:category?","/en/:theme(theme-company)?/career/career-blog/:category?","/en/:theme(theme-company)?/karriere/karriere-blog/:category?","/de/:theme(theme-company)?/career/career-blog/:category?","/de/:theme(theme-company)?/karriere/karriere-blog/:category?"].concat(BlogOverviewDxXAaAhOtFMeta?.alias || []),
    meta: {...(BlogOverviewDxXAaAhOtFMeta || {}), ...{"cacheMaxAgeInSeconds":86400}},
    redirect: BlogOverviewDxXAaAhOtFMeta?.redirect || undefined,
    component: () => import("/views/blog/BlogOverview.vue").then(m => m.default || m)
  }
],
    meta: {...(Blog3L73v8TKD8Meta || {}), ...{"cacheMaxAgeInSeconds":86400}},
    redirect: Blog3L73v8TKD8Meta?.redirect || undefined,
    component: () => import("/views/blog/Blog.vue").then(m => m.default || m)
  },
  {
    name: BlogDetailbPRxPBZWg7Meta?.name ?? "blog-detail",
    path: BlogDetailbPRxPBZWg7Meta?.path ?? "/en/:theme(theme-company)?/career/career-blog/detail/:path(.*)",
    alias: ["/de/:theme(theme-company)?/karriere/karriere-blog/detail/:path(.*)","/en/:theme(theme-company)?/career/career-blog/detail/:path(.*)","/en/:theme(theme-company)?/karriere/karriere-blog/detail/:path(.*)","/de/:theme(theme-company)?/career/career-blog/detail/:path(.*)","/de/:theme(theme-company)?/karriere/karriere-blog/detail/:path(.*)"].concat(BlogDetailbPRxPBZWg7Meta?.alias || []),
    meta: {...(BlogDetailbPRxPBZWg7Meta || {}), ...{"cacheMaxAgeInSeconds":86400}},
    redirect: BlogDetailbPRxPBZWg7Meta?.redirect || undefined,
    component: () => import("/views/blog/BlogDetail.vue").then(m => m.default || m)
  },
  {
    name: Content6w4NcPiXO3Meta?.name ?? "content-page",
    path: Content6w4NcPiXO3Meta?.path ?? "/:lang(en|de)/:theme(theme-company)?/:path(.*)",
    meta: {...(Content6w4NcPiXO3Meta || {}), ...{"cacheMaxAgeInSeconds":86400}},
    alias: Content6w4NcPiXO3Meta?.alias || [],
    redirect: Content6w4NcPiXO3Meta?.redirect || undefined,
    component: () => import("/views/content/Content.vue").then(m => m.default || m)
  }
]