export default defineNuxtPlugin(async (nuxtApp) => {
  const app = nuxtApp.vueApp.$nuxt;
  if (!app.$mopI18n) {
    return;
  }
  const lang = app.$mopI18n.lang;

  const data = await import(`@/i18n/locale/${lang}.json`);

  const urls = data.urls;

  nuxtApp.provide('urls', urls);
});
