import type { ViewportSize } from '@/types/breakpoint';

export const COOKIE: Record<string, string> = {
  PRIVACY: 'company_privacy',
  ACCEPTED_COOKIE_CONSENT_VERSION: 'accepted-cookie-consent-version',
  DEBUG: 'debug',
  LANGUAGE: 'lang',
};

export const SCROLL_DIRECTION_UP = 'up';
export const SCROLL_DIRECTION_DOWN = 'down';

export const BREAKPOINT = {
  TINY: 'xs' as ViewportSize,
  SMALL: 's' as ViewportSize,
  MEDIUM: 'm' as ViewportSize,
  LARGE: 'l' as ViewportSize,
  EXTRA_LARGE: 'xl' as ViewportSize,
  EXTRA_EXTRA_LARGE: 'xxl' as ViewportSize,
  INFINITE: 'infinite' as ViewportSize,
};

export default {
  KEY_CODES: {
    ESCAPE: 27,
  },
  ERROR_CODE: {
    NOT_FOUND: 404,
  },
  OVERLAYS: {
    COOKIE: {
      URL_HASH: 'overlay-cookie',
    },
  },
  META: {
    INDEXABLE: 'index, follow',
    NOT_INDEXABLE: 'noindex, follow',
  },
  RUNTIME_ENVIRONMENT: {
    PRODUCTION: 'production',
    DEVELOPMENT: 'development',
  },
  TRANSITION_TIME: {
    ENTER: 1000,
    LEAVE: 200,
  },
  STORYBLOK: {
    VISIBILITY_ALL: 'All',
    VISIBILITY_HIDDEN: 'Hidden',
    STORY_PATHS: {
      COOKIE_BANNER: '/cake-menu',
      ERROR_PAGE: '/not-found',
      JOB_SEARCH_PAGE: '/career/start-creating-with-us/our-jobs',
    },
  },
  JOBS: {
    DEPARTMENT: {
      FRANCHISE: 'Franchise',
      CONCESSION: 'Concession',
    },
  },
  SCROLL_DIRECTION_UP: 'up',
  SCROLL_DIRECTION_DOWN: 'down',
};
