import type { Directive, DirectiveBinding } from 'vue';
import { throttle } from '@mop/shared/utils/util';
import type { ActionPosition, ObserveScrollConfig } from '@mop/types';

// eslint-disable-next-line no-use-before-define
type HtmlElementScroll = HTMLElement & { _vue_scrollState: ScrollState | null };

class ScrollState {
  actionPositions: ActionPosition[];
  currentActionPositionIndex: number | null;
  isAllowedViewportRef: Ref<boolean>;
  stopWatchBreakpoints: Function | undefined;
  stopWatchAllowedViewPort: Function | undefined;
  stopWatchResize: Function | undefined;
  stopWatchScroll: Function | undefined;
  el: HtmlElementScroll | null;

  constructor(el: HtmlElementScroll, config: ObserveScrollConfig) {
    this.el = el;
    this.actionPositions = [];
    this.currentActionPositionIndex = null;
    this.isAllowedViewportRef = ref(false);
    this.startWatch(el, config);
  }

  startWatch(el: HtmlElementScroll, config: ObserveScrollConfig) {
    this.watchBreakpoints(config);
    this.watchAllowedViewPort(el, config);
  }

  watchBreakpoints(config: ObserveScrollConfig) {
    const {
      $breakpoint: { currentRef: currentViewportRef },
    } = useNuxtApp();

    if (!config.allowedViewports) {
      this.isAllowedViewportRef.value = true;
      return;
    }

    this.stopWatchBreakpoints = watch(
      currentViewportRef,
      () => {
        this.isAllowedViewportRef.value = config.allowedViewports?.includes(currentViewportRef.value) ?? false;
      },
      { immediate: true },
    );
  }

  watchAllowedViewPort(el: HtmlElementScroll, config: ObserveScrollConfig) {
    this.stopWatchAllowedViewPort = watch(
      this.isAllowedViewportRef,
      () => {
        if (this.isAllowedViewportRef.value) {
          // watch
          this.watchResize(el, config);
          this.watchScroll(config);
        } else {
          // unwatch scroll and resize
          this.stopWatchResize && this.stopWatchResize();
          this.stopWatchScroll && this.stopWatchScroll();

          // Leave eventual action position
          this.currentActionPositionIndex !== null &&
            this.actionPositions[this.currentActionPositionIndex]?.onLeave &&
            // @ts-ignore
            this.actionPositions[this.currentActionPositionIndex].onLeave();
          this.currentActionPositionIndex = null;
        }
      },
      { immediate: true },
    );
  }

  watchResize(el: HtmlElementScroll, config: ObserveScrollConfig) {
    const {
      $resize: { viewportWidthRef, viewportHeightRef, documentHeightRef },
      $scroll: { scrollDirectionRef, offsetTopRef },
    } = useNuxtApp();

    this.stopWatchResize = watch(
      [viewportWidthRef, viewportHeightRef, documentHeightRef],
      () => {
        this.actionPositions =
          config.calculateActionPositions(
            el,
            viewportWidthRef.value,
            viewportHeightRef.value,
            documentHeightRef.value,
          ) ?? [];
        this.handleActiveActionPosition(scrollDirectionRef.value, offsetTopRef.value);
      },
      { immediate: true },
    );
  }

  watchScroll(config: ObserveScrollConfig) {
    const {
      $scroll: { scrollDirectionRef, offsetTopRef },
    } = useNuxtApp();

    const throttleHandler: any = throttle(() => {
      this.handleActiveActionPosition(scrollDirectionRef.value, offsetTopRef.value);
    }, config.throttleDelay ?? 200);

    this.stopWatchScroll = watch(
      [scrollDirectionRef, offsetTopRef],
      () => {
        throttleHandler();
      },
      { immediate: true },
    );
  }

  unWatch() {
    this.stopWatchBreakpoints && this.stopWatchBreakpoints();
    this.stopWatchAllowedViewPort && this.stopWatchAllowedViewPort();
    this.stopWatchResize && this.stopWatchResize();
    this.stopWatchScroll && this.stopWatchScroll();

    this.actionPositions = [];
    this.currentActionPositionIndex = null;
    this.isAllowedViewportRef.value = false;
    if (this.el) {
      this.el._vue_scrollState = null;
    }
    this.el = null;
  }

  calculateCurrentActivePositionIndex(scrollDirection: string, offsetTop: number): number | null {
    let index: number | null = null;

    if (this.actionPositions.length === 0) {
      return index;
    }

    // Loop through action positions, last found wins
    this.actionPositions.forEach((actionPosition, i) => {
      // Skip, if scroll direction doesn't mach
      if (actionPosition.scrollDirection && actionPosition.scrollDirection !== scrollDirection) {
        return;
      }

      // Check, if specific action position reached
      if (
        offsetTop >= actionPosition.positionStart &&
        (!actionPosition.positionEnd || offsetTop <= actionPosition.positionEnd)
      ) {
        index = i;
      }
    });

    return index;
  }

  handleActiveActionPosition(scrollDirection: string, offsetTop: number) {
    let newActionPositionIndex: number | null = null;
    if (this.isAllowedViewportRef?.value) {
      newActionPositionIndex = this.calculateCurrentActivePositionIndex(scrollDirection, offsetTop);
    }

    // Action position did not change
    if (this.currentActionPositionIndex === newActionPositionIndex) {
      return;
    }

    // New action position reached
    const oldActionPositionHasOnLeave: boolean =
      this.currentActionPositionIndex !== null &&
      this.actionPositions[this.currentActionPositionIndex] &&
      typeof this.actionPositions[this.currentActionPositionIndex].onLeave === 'function';
    if (oldActionPositionHasOnLeave) {
      // @ts-ignore
      this.actionPositions[this.currentActionPositionIndex].onLeave();
    }
    const newActionPositionHasOnEnter: boolean =
      newActionPositionIndex !== null &&
      this.actionPositions[newActionPositionIndex] &&
      typeof this.actionPositions[newActionPositionIndex].onEnter === 'function';
    if (newActionPositionHasOnEnter) {
      // @ts-ignore
      this.actionPositions[newActionPositionIndex].onEnter();
    }

    this.currentActionPositionIndex = newActionPositionIndex;
  }
}

export const scrollDirective: Directive = {
  mounted(el: HtmlElementScroll, { value }: DirectiveBinding) {
    nextTick(() => {
      // Make sure dom is rendering element correctly and provides correct boundings
      setTimeout(() => {
        el._vue_scrollState = new ScrollState(el, value);
      }, 100);
    });
  },

  unmounted(el: HtmlElementScroll) {
    const state = el._vue_scrollState;
    if (state) {
      state.unWatch();
      el._vue_scrollState = null;
    }
  },
};
