import revive_payload_client_YTGlmQWWT5 from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_neSs9z3UJp from "/opt/build/repo/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_VeD7Ba96AP from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_VTarsGAzgb from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_LIYcCMJD18 from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "/opt/build/repo/apps/company/.nuxt/components.plugin.mjs";
import prefetch_client_8FoqvPFTot from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import chunk_reload_client_SeG0EjL5Ec from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import datadog_UFV22wWpxX from "/opt/build/repo/apps/company/plugins/datadog.ts";
import cookie_fY1uPxNcjB from "/opt/build/repo/apps/company/plugins/cookie.ts";
import debug_ZiGB8yHUre from "/opt/build/repo/apps/company/plugins/debug.ts";
import composableStorage_yMAjS8MWOd from "/opt/build/repo/apps/company/plugins/composableStorage.ts";
import errorHandler_x1Y4n5NP4w from "/opt/build/repo/apps/company/plugins/errorHandler.ts";
import resizeHandler_WBjVe1R1OX from "/opt/build/repo/apps/company/plugins/resizeHandler.ts";
import scrollHandler_vsgC7U9b62 from "/opt/build/repo/apps/company/plugins/scrollHandler.ts";
import breakpoint_JVnKhWJKDQ from "/opt/build/repo/apps/company/plugins/breakpoint.ts";
import storyblokLivePreview_whcxKqA7YO from "/opt/build/repo/apps/company/plugins/storyblokLivePreview.ts";
import mopI18n_aIiIC8irec from "/opt/build/repo/apps/company/plugins/mopI18n.ts";
import appInit_RZ6eE2UnES from "/opt/build/repo/apps/company/plugins/appInit.ts";
import gtm_cXsUZobmSF from "/opt/build/repo/apps/company/plugins/gtm.ts";
import urls_hQgrCsbIDt from "/opt/build/repo/apps/company/plugins/urls.ts";
import ui2_gTgeYASmAv from "/opt/build/repo/apps/company/plugins/ui2.ts";
import directives_8CcCirWtnE from "/opt/build/repo/apps/company/plugins/directives.ts";
export default [
  revive_payload_client_YTGlmQWWT5,
  unhead_neSs9z3UJp,
  router_VeD7Ba96AP,
  payload_client_VTarsGAzgb,
  check_outdated_build_client_LIYcCMJD18,
  components_plugin_KR1HBZs4kY,
  prefetch_client_8FoqvPFTot,
  chunk_reload_client_SeG0EjL5Ec,
  datadog_UFV22wWpxX,
  cookie_fY1uPxNcjB,
  debug_ZiGB8yHUre,
  composableStorage_yMAjS8MWOd,
  errorHandler_x1Y4n5NP4w,
  resizeHandler_WBjVe1R1OX,
  scrollHandler_vsgC7U9b62,
  breakpoint_JVnKhWJKDQ,
  storyblokLivePreview_whcxKqA7YO,
  mopI18n_aIiIC8irec,
  appInit_RZ6eE2UnES,
  gtm_cXsUZobmSF,
  urls_hQgrCsbIDt,
  ui2_gTgeYASmAv,
  directives_8CcCirWtnE
]