import { datadogRum } from '@datadog/browser-rum';

function CustomError(error: Error) {
  // @ts-ignore
  this.name = error?.name ?? 'CustomError';
  // @ts-ignore
  this.message = error?.message ?? 'CustomError';
  // @ts-ignore
  this.stack = error?.stack ?? '';
}

CustomError.prototype = Object.create(Error.prototype);

export default defineNuxtPlugin((nuxtApp) => {
  if (isPrerender() || isLinux()) {
    return;
  }
  const config = useRuntimeConfig();
  const applicationId: string = config.public.DATADOG_APP_KEY ?? '';
  const clientToken: string = config.public.DATADOG_TOKEN ?? '';
  const version: string = config.public.BUILD_ID ?? '';
  if (!applicationId || !clientToken) {
    return;
  }
  const productionHost: string = config.public.DATADOG_APPLICATION_NAME;

  datadogRum.init({
    applicationId,
    clientToken,
    site: 'datadoghq.com',
    service: productionHost,
    version,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackResources: true,
    trackLongTasks: true,
    trackUserInteractions: true,
    defaultPrivacyLevel: 'mask-user-input',
  });

  // @ts-ignore
  window.appInsights = {
    trackException({ exception }: { exception: Error }) {
      try {
        // @ts-ignore
        throw new CustomError(exception);
      } catch (error) {
        datadogRum.addError(error);
      }
    },
    trackTrace({ message, severity }: { message: string; severity: string }) {
      try {
        // @ts-ignore
        throw new CustomError({
          name: 'Trace',
          message: `Trace [${severity}]: ${message}`,
        });
      } catch (error) {
        datadogRum.addError(error);
      }
    },
    flush() {},
  };

  nuxtApp.provide('datadog', datadogRum);
});
