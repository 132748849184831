/**
 * Storyblok bridge plugin for live preview
 */

import { loadScript } from '@mop/shared/utils/util';
import { securedWrap } from '@mop/shared/utils/securedWrap';
import { cmsStoryModel } from '@/models';
import type { MopStoryblokLivePreview, CmsStoryModel } from '@/types/cms';

export default defineNuxtPlugin((nuxtApp) => {
  let scriptLoaded = false;
  // @ts-ignore
  let storyblok: StoryblokBridge;
  const route = useRoute();
  const isStoryblokLivePreview = Boolean(route.query._storyblok_lang);
  const isStoryblokLivePreviewIframe = window.top !== window.self;
  const cmsStoryModelRefs: any = {};
  function loadStoryblokJs(callback: () => void) {
    if (!isStoryblokLivePreview) {
      return;
    }
    if (scriptLoaded) {
      // @ts-ignore
      if (!window.StoryblokBridge) {
        return;
      }
      callback();
      return;
    }
    scriptLoaded = true;
    loadScript({
      source: 'https://app.storyblok.com/f/storyblok-v2-latest.js',
      callback,
      triggerCallbackIfExists: true,
    });
  }
  loadStoryblokJs(() => {
    // @ts-ignore
    storyblok = new StoryblokBridge();
    storyblok.on('input', (event: any) => {
      const keys: string[] = Object.keys(cmsStoryModelRefs);
      // Update modfied cmsStoryModelRefs, that are listening
      keys.forEach((key) => {
        const cmsStoryModelRef: Ref<CmsStoryModel> = cmsStoryModelRefs[key];
        if (event.story.id === cmsStoryModelRef.value?.getId()) {
          cmsStoryModelRef.value = cmsStoryModel({ data: event.story });
        }
      });
    });
  });

  const storyblokLivePreview: MopStoryblokLivePreview = securedWrap({
    initStoryListener(cacheKey, cmsStoryModelRef) {
      if (!isStoryblokLivePreview) {
        return;
      }
      onMounted(() => {
        cmsStoryModelRefs[cacheKey] = cmsStoryModelRef;
      });
      onUnmounted(() => {
        delete cmsStoryModelRefs[cacheKey];
      });
    },
    isScriptLoaded() {
      return storyblok !== undefined;
    },
    isEnabled: isStoryblokLivePreview,
    isEnabledInIframe: isStoryblokLivePreviewIframe,
  });
  nuxtApp.payload.data.storyblokLivePreviewEnabled = isStoryblokLivePreviewIframe;
  nuxtApp.provide('storyblokLivePreview', storyblokLivePreview);
});
