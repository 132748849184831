import type { RouteLocationNormalizedLoaded } from 'vue-router';
import { isNavigationFailure, NavigationFailureType } from 'vue-router';
import { logError } from '@mop/shared/utils/logger';

export async function routeChangeMiddleware(route: RouteLocationNormalizedLoaded) {
  await handleOverlays();

  async function handleOverlays(): Promise<void> {
    const overlay = useMopOverlay();

    if (route.name) {
      overlay.canCloseOnRouteChange(route.name.toString()) && (await overlay.closeAll());
    }

    if (route.hash && overlay.openFromUrlHash(route.hash?.substring(1))) {
      const router = useRouter();
      router.replace(router.currentRoute.value.fullPath).catch((error) => {
        if (isNavigationFailure(error, NavigationFailureType.duplicated)) {
          return;
        }
        logError(error);
      });
    }
  }
}
