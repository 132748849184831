import type { AxiosError, AxiosInstance } from 'axios';
import type { StatusResponseData } from '@/types/status';
import { handleAxiosError, errorHandler } from '@/api/utils';

const API_NAME = 'AWS - application';

export function sendApplication(apiInstance: AxiosInstance, params: any): Promise<StatusResponseData> {
  return errorHandler<StatusResponseData>(async () => ({
    data: (await apiInstance.post('/application', params)).data,
  })).catch((error: AxiosError) => {
    if (error.response?.data) {
      return {
        data: error.response.data,
      };
    }
    return {
      error: handleAxiosError(error, {
        api: API_NAME,
        method: 'sendApplication',
        params,
      }),
    };
  });
}
