import { logError } from '@mop/shared/utils/logger';
import { cmsStoryModel, countryConfigModel } from '@/models';
import { createApiCms, createApiAws } from '@/api';
import { navigationListModel } from '@/models/navigationModel';
import { routeChangeMiddleware } from '@/middleware/routeChange';

export default defineNuxtPlugin(async (nuxtApp) => {
  const app = nuxtApp.vueApp.$nuxt;
  if (!app.$mopI18n) {
    return;
  }
  const isStoryblokLivePreview: boolean = app.$storyblokLivePreview.isEnabled;
  const route = useRoute();
  const config = useRuntimeConfig();

  function getCmsLanguages(): { cmsLanguage: string; cmsFallbackLanguage: string } {
    const STORYBLOK_DEFAULT_PARAMETER_LANGUAGE_KEY = 'default';
    try {
      let cmsLanguage = app.$mopI18n.locale;
      let cmsFallbackLanguage = app.$mopI18n.cmsFallbackLanguage || STORYBLOK_DEFAULT_PARAMETER_LANGUAGE_KEY;
      const storyblokLanguageParameter: string = route.query._storyblok_lang as string;

      if (storyblokLanguageParameter) {
        cmsLanguage = storyblokLanguageParameter;
        if (cmsLanguage === STORYBLOK_DEFAULT_PARAMETER_LANGUAGE_KEY) {
          cmsLanguage = defaultStoryblokLanguage;
          cmsFallbackLanguage = '';
        }
      }

      if (cmsLanguage === cmsFallbackLanguage) {
        cmsFallbackLanguage = '';
      }

      return {
        cmsLanguage,
        cmsFallbackLanguage,
      };
    } catch (error) {
      logError(error);
    }
    return {
      cmsLanguage: app.$mopI18n.locale,
      cmsFallbackLanguage: STORYBLOK_DEFAULT_PARAMETER_LANGUAGE_KEY,
    };
  }

  // @todo: figure out another way to redirect same as in shop
  // if (window.navigator.userAgent.includes('MSIE') || window.navigator.appVersion.includes('Trident/')) {
  //   /* Microsoft Internet Explorer detected in. */
  //   if (route?.name !== 'browser-not-supported') {
  //     const locale = app.$mopI18n.locale;
  //     window.location.href = `/${locale}/${urls[locale as keyof typeof urls].BROWSER_NOT_SUPPORTED}`;
  //     return;
  //   }
  // }

  const { cmsLanguage, cmsFallbackLanguage } = getCmsLanguages();
  const apiCms = createApiCms({
    cmsApiKey: isStoryblokLivePreview
      ? config.public.STORYBLOK_API_KEY_PREVIEW
      : config.public.STORYBLOK_API_KEY_PUBLISHED,
    cmsVersion: isStoryblokLivePreview ? 'draft' : 'published',
    cmsLanguage,
    cmsFallbackLanguage,
    cmsRelease: route.query._storyblok_release as string,
  });
  nuxtApp.provide('apiCms', apiCms);
  nuxtApp.provide(
    'apiCompanyAws',
    createApiAws({
      url: config.public.AWS_URL,
    }),
  );

  const globalConfig = cmsStoryModel(await apiCms.getStory('/theme-company/global'));
  nuxtApp.provide('mopConfig', countryConfigModel(globalConfig.getAttribute('config')?.[0]));
  nuxtApp.provide('navigationList', navigationListModel(globalConfig.getAttribute('navigation')).getList());
  nuxtApp.provide(
    'footerNavigationList',
    navigationListModel(globalConfig.getAttribute('footer_navigation')).getList(),
  );
  nuxtApp.provide('socialLinkList', navigationListModel(globalConfig.getAttribute('social_links')).getList());

  addRouteMiddleware(async (to) => {
    await routeChangeMiddleware(to);
  });

  // @todo: uncomment when migrated
  // usePrivacy(middlewareInstance).initCookies();
});
