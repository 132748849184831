import { logError, logInfo } from '@mop/shared/utils/logger';
import { sessionStorageGet, sessionStorageSet } from '@mop/shared/utils/sessionStorage';

export default defineNuxtPlugin((nuxtApp) => {
  // Opted out from app:error logging the errors for now.

  nuxtApp.errorHandler = function errorHandler(error: Error, info: string) {
    logError(error);
    logInfo(`Error triggered using: ${info}`);
    // mark as handled
    return true;
  };
  window.addEventListener(
    'error',
    function (event: any) {
      event.preventDefault();
      event.stopPropagation();
      const jsChunkSrc: string | undefined = event.target?.src;
      if (
        jsChunkSrc === undefined ||
        jsChunkSrc === '' ||
        jsChunkSrc.indexOf(`${window.location.origin}/_nuxt`) !== 0
      ) {
        // No nuxt js chunk
        return;
      }

      const lastCheckTime: number | undefined =
        // @ts-ignore
        parseInt(sessionStorageGet('ServerCodeVersionCheckTime')) || undefined;
      if ((!lastCheckTime || Date.now() - lastCheckTime > 3600000) && !isPrerender()) {
        sessionStorageSet('ServerCodeVersionCheckTime', String(Date.now()));
        setTimeout(() => {
          location.reload();
        }, 50);
      }
    },
    true,
  );
});
