import type { AxiosError, AxiosInstance } from 'axios';
import type { StatusResponseData } from '@/types/status';
import { handleAxiosError, errorHandler } from '@/api/utils';

const API_NAME = 'AWS - contact request';

export function sendContactRequest(apiInstance: AxiosInstance, params: any): Promise<StatusResponseData> {
  return errorHandler<StatusResponseData>(async () => ({
    data: (await apiInstance.post('/contact-request', params)).data,
  })).catch((error: AxiosError) => ({
    error: handleAxiosError(error, {
      api: API_NAME,
      method: 'sendContactEmail',
      params,
    }),
  }));
}
