import ui2Plugin from '@mop/ui2/config/ui2Plugin';

export default defineNuxtPlugin((nuxtApp) => {
  const app = nuxtApp.vueApp.$nuxt;
  if (!app.$mopI18n) {
    return;
  }

  // bridge to ui2 components localisation, config etc
  nuxtApp.vueApp.use(ui2Plugin, {
    translations: {
      fileUpload: {
        click_to_upload: app.$mopI18n.t('fileUpload.click_to_upload'),
        drag_document_here: app.$mopI18n.t('fileUpload.drag_document_here'),
        drag_content: app.$mopI18n.t('fileUpload.drag_content'),
        unsupported_file: app.$mopI18n.t('fileUpload.unsupported_file'),
      },
    },
  });
});
