import type { AxiosError, AxiosInstance } from 'axios';
import { handleAxiosError, errorHandler } from '@/api/utils';

const API_NAME = 'AWS - vacancies';

export function getVacancies(apiInstance: AxiosInstance, language: string): Promise<any> {
  return errorHandler<any>(async () => ({
    data: (
      await apiInstance.get('/vacancies', {
        params: {
          language,
        },
      })
    ).data,
  })).catch((error: AxiosError) => ({
    error: handleAxiosError(error, {
      api: API_NAME,
      method: getVacancies.name,
    }),
  }));
}

export function getVacancy(apiInstance: AxiosInstance, id: string, language: string): Promise<any> {
  return errorHandler<any>(async () => ({
    data: (
      await apiInstance.get(`/vacancies/${id}`, {
        params: {
          language,
        },
      })
    ).data,
  })).catch((error: AxiosError) => ({
    error: handleAxiosError(error, {
      api: API_NAME,
      method: getVacancy.name,
    }),
  }));
}
