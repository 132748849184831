import { escapeStoryblokUrl } from '@mop/cms/utils/utils';
import type {
  CmsStoryResponseData,
  CmsContentElementModel,
  CmsStoryModel,
  CmsStoryListResponseData,
  CmsStorySeoData,
  CmsVisibility,
  CmsTranslatedSlugData,
  Alternate,
  CmsContentElementListResponseData,
} from '@/types/cms';
import { cmsContentElementListModel } from '@/models';

export function cmsStoryModel(responseData: CmsStoryResponseData | null) {
  const response: CmsStoryResponseData = responseData ?? {};

  return {
    hasError(): boolean {
      return response.error !== undefined;
    },

    hasErrorNotFound(): boolean {
      return response.error?.code === 404;
    },

    isInitialized(): boolean {
      return responseData !== null;
    },

    getId(): number {
      return response.data?.id ?? 0;
    },

    getUuId(): string {
      return response.data?.uuid ?? '';
    },

    getName(): string {
      return response.data?.name ?? '';
    },

    getCreatedAt(): string {
      return response.data?.created_at ?? '';
    },

    getPublishedAt(): string {
      return response.data?.published_at ?? '';
    },

    getSlug(): string {
      return response.data?.slug ?? '';
    },

    getFullSlug(): string {
      return response.data?.full_slug ?? '';
    },

    getParentId(): number {
      return response.data?.parent_id ?? 0;
    },

    getContentUid(): string {
      return response.data?.content?._uid ?? '';
    },

    getContentComponent(): string {
      return response.data?.content?.component ?? '';
    },

    getContentEditable(): string {
      return response.data?.content?._editable ?? '';
    },

    getContentEditableObject(): any {
      return {
        _editable: this.getContentEditable(),
      };
    },

    getDebugData(): any {
      return response.data;
    },

    getResponse(): CmsStoryResponseData {
      return response;
    },

    getVisibility(): CmsVisibility | undefined {
      return response.data?.content?.visibility;
    },

    getSeo(): CmsStorySeoData {
      const content: any = response.data?.content || {};
      return {
        title: content.seo?.title,
        description: content.seo?.description,
        isIndexable: content.isIndexable !== 'no',
      };
    },

    getCanonical(): string | undefined {
      const canonicalObject: any = response.data?.content?.canonical;

      if (!canonicalObject) {
        return;
      }
      const story: any = canonicalObject.story;
      const url: any = canonicalObject.url;
      const isValidCanonical: boolean = story || url;
      if (!isValidCanonical) {
        return;
      }

      if (!story) {
        return url;
      }

      return escapeStoryblokUrl(story.full_slug || this.getFullSlug());
    },

    getAlternates(countryLocaleSlugsOnly = true): Alternate[] {
      // @ts-ignore
      const slugs: CmsTranslatedSlugData[] = response.data?.translated_slugs;
      if (!slugs) {
        return [];
      }

      const disallowedSlugs: string[] = ['ge-ge'];
      return slugs.reduce((list: Alternate[], slug: CmsTranslatedSlugData) => {
        const langSplit: string[] = slug.lang?.split('-') || [];
        if (!slug?.lang || disallowedSlugs.includes(slug.lang) || (countryLocaleSlugsOnly && langSplit.length !== 2)) {
          return list;
        }
        list.push({
          href: escapeStoryblokUrl(slug.path),
          lang: slug.lang as string,
        });
        return list;
      }, []);
    },

    getAttribute(name: string): any {
      return response.data?.content?.[name];
    },

    getContent(): any {
      return response.data?.content;
    },

    getContentElements(attributes: string | string[] = 'body'): CmsContentElementModel[] {
      const attributesArray: string[] = Array.isArray(attributes) ? attributes : [attributes];

      return attributesArray.reduce((contentElements: CmsContentElementModel[], attribute: string) => {
        const data: any = response.data?.content?.[attribute];
        if (!data) {
          return contentElements;
        }
        const modifiedData: any = data.reduce((dataElements: any, dataItem: any) => {
          // flatten items from the group to current level
          if (dataItem?.component === 'Group') {
            dataElements.push(...dataItem.items);
            return dataElements;
          }
          dataElements.push(dataItem);
          return dataElements;
        }, []);
        contentElements.push(...this.getElements(modifiedData));
        return contentElements;
      }, []);
    },

    hasContentElements(attribute = 'body'): boolean {
      return (
        response.data?.content && response.data?.content[attribute] && response.data?.content[attribute].length > 0
      );
    },

    getElements(content?: any): CmsContentElementModel[] {
      const data: CmsContentElementListResponseData | null = content === undefined ? null : { data: content };
      return cmsContentElementListModel(data).getCmsContentElementModelList();
    },

    getContentElementsByCustomId(customId: string, attribute?: string): CmsContentElementModel[] {
      return this.getContentElements(attribute).filter((contentElement) => contentElement.getCustomId() === customId);
    },

    isNewsArticle() {
      return this.getContentComponent() === 'CompanyNewsDetailPage';
    },

    isBlogPost() {
      return this.getContentComponent() === 'CompanyBlogDetailPage';
    },
  };
}

export function cmsStoryListModel(responseData: CmsStoryListResponseData | null) {
  const response: CmsStoryListResponseData = responseData ?? {};

  return {
    isInitialized(): boolean {
      return responseData !== null;
    },

    hasError(): boolean {
      return response.error !== undefined;
    },

    getTotal(): number {
      return response.data?.total ?? 0;
    },

    getPerPage(): number {
      return response.data?.perPage ?? 0;
    },

    getData(): any {
      return response.data;
    },

    getStoryModelByName(name?: string): CmsStoryModel | undefined {
      return this.getStoryModelList()?.find((storyModel) => storyModel.getName() === name);
    },

    getStoryModelList(): CmsStoryModel[] {
      if (response.data === undefined) {
        return [];
      }
      return response.data.data.stories.map((story: CmsStoryResponseData) => cmsStoryModel({ data: story }));
    },
  };
}
