import { securedWrap } from '@mop/shared/utils/securedWrap';
import { loadScript } from '@mop/shared/utils/util';
import type { GtmPageViewParams } from '@/types/gtm';
const MAX_DATALAYER_BULK_SIZE = 24;

export default defineNuxtPlugin((nuxtApp) => {
  const app = nuxtApp.vueApp.$nuxt;
  if (!app.$mopI18n) {
    return;
  }

  const { $debug, $mopI18n, $storyblokLivePreview } = app;
  const router = useRouter();
  const route = useRoute();
  const config = useRuntimeConfig();

  // @todo: migrate
  // const { isCookieConsentRequired } = usePrivacy();
  const isCookieConsentRequired = () => false;
  let isGtmScriptAdded = false;
  let datalayerBulk: any = [];
  let currentPageType = '';

  if (isEnabled()) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      originalLocation:
        document.location.protocol +
        '//' +
        document.location.hostname +
        document.location.pathname +
        document.location.search,
    });
    window.addEventListener('beforeunload', commitDatalayer);
    window.addEventListener('blur', commitDatalayer);

    initVueRouterGuard();
  }

  function initVueRouterGuard() {
    router.beforeEach((to) => {
      if (!to.fullPath) {
        return;
      }
      commitDatalayer();
    });
  }

  function isEnabled() {
    return (
      config.public.GTM_ENABLE === true &&
      config.public.GTM_CONTAINER_ID !== '' &&
      !route.query.disablegtm &&
      $storyblokLivePreview.isEnabled === false
    );
  }

  function trackView() {
    if (!isEnabled()) {
      return;
    }
    const event: any = {
      event: 'page-view',
      dl_page: document.location.pathname,
    };
    $debug.log(`GtmClient: Dispatching TrackView ${JSON.stringify(event)}`);
    window.dataLayer.push(event);
  }

  function trackPageView(pageViewEvent: GtmPageViewParams) {
    if (!isEnabled()) {
      return;
    }
    currentPageType = pageViewEvent.pageType ?? '';
    pageViewEvent.pageLanguageCode ??= $mopI18n.lang;
    $debug.log(`GtmClient: Dispatching TrackPageView ${JSON.stringify(pageViewEvent)}`);
    window.dataLayer.push(pageViewEvent);
    trackView();
  }

  function trackEvent(
    {
      eventName,
      parameters,
      customValue,
      callbackFn,
    }: { eventName: string; parameters?: string[]; customValue?: any; callbackFn?: () => void },
    ignoreBundling = false,
  ) {
    if (!isEnabled()) {
      return;
    }

    const targetLayer: any = ignoreBundling || callbackFn ? window.dataLayer : datalayerBulk;
    const params: Record<string, string> =
      customValue ||
      (parameters ?? []).reduce((list: Record<string, string>, value: string, index: number) => {
        list[`parameter${index + 1}`] = value;
        return list;
      }, {});
    const event: any = {
      event: eventName,
      ...params,
    };
    if (callbackFn) {
      event.eventCallback = callbackFn;
    }
    $debug.log(`GtmClient: Dispatching event ${JSON.stringify(event)}`);
    targetLayer.push(event);
    if (datalayerBulk.length >= MAX_DATALAYER_BULK_SIZE) {
      commitDatalayer();
    }
  }

  function commitDatalayer() {
    // eslint-disable-next-line prefer-spread
    window.dataLayer.push.apply(window.dataLayer, datalayerBulk);
    datalayerBulk = [];
  }

  function getCurrentPageType() {
    return currentPageType;
  }

  function initGtmScript() {
    if (isGtmScriptAdded || isCookieConsentRequired()) {
      return;
    }
    isGtmScriptAdded = true;
    loadScript({
      source: `https://www.googletagmanager.com/gtm.js?id=${config.public.GTM_CONTAINER_ID}`,
      callback: () => {
        window.dataLayer.push({
          event: 'gtm.js',
          'gtm.start': Date.now(),
          'gtm.uniqueEventId': 0,
        });
      },
    });
  }

  nuxtApp.provide(
    'gtm',
    securedWrap({
      isEnabled,
      trackEvent,
      trackView,
      trackPageView,
      getCurrentPageType,
      initGtmScript,
    }),
  );
});
