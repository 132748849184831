import type { CountryConfig } from '@/types/countryConfig';

export function countryConfigModel(responseData: CountryConfig | null) {
  const response: CountryConfig | null = responseData;
  return {
    isInitialized(): boolean {
      return response !== null;
    },

    getGtmId(): string {
      return response?.gtmId ?? '';
    },

    getGtmDelay(): number {
      return parseInt(response?.gtmDelay ?? '0');
    },

    isGlobalEEnabled(): boolean {
      return false;
    },

    getSupportEmail(): string {
      return response?.supportEmail ?? '';
    },

    getHeaderJobButtonColor(): string {
      return response?.jobButtonColor?.color ?? '#000';
    },

    getJobSearchBgColor(): string {
      return response?.jobSearchBgColor?.color ?? '#000';
    },

    getApplyNowButtonColor(): string {
      return response?.applyNowButtonColor?.color ?? '#000';
    },

    getAvailableNewsCategories(): string[] {
      return response?.availableNewsCategories ?? [];
    },
  };
}
