import type { AxiosInstance } from 'axios';
import { sendContactRequest } from './apiContactRequest';
import { getVacancy, getVacancies } from './apiVacancies';
import { sendApplication } from './apiApplication';

export default function initApiAws(apiInstance: AxiosInstance) {
  return {
    sendContactRequest: (params: any) => sendContactRequest(apiInstance, params),
    getVacancies: (language: string) => getVacancies(apiInstance, language),
    getVacancy: (id: string, language: string) => getVacancy(apiInstance, id, language),
    sendApplication: (params: any) => sendApplication(apiInstance, params),
  };
}

export type ApiAws = ReturnType<typeof initApiAws>;
