import { logWarning } from '@mop/shared/utils/logger';
import { securedWrap } from '@mop/shared/utils/securedWrap';
import type { CookieOptions } from '@mop/shared/utils/cookie';
import type { PrivacySettings, UsePrivacy } from '@/types/privacy';

const COOKIE_CONSENT_VERSION = 1;
const COOKIE_CONSENT_VERSION_WILDCARD = '*';
const PRIVACY_LIFESPAN_SHORT = 14; // privacy cookies lifetime in days
const PRIVACY_LIFESPAN_LONG = 180; // privacy cookies lifetime in days
const MARKETING_COOKIES: string[] = [];
const TRACKING_COOKIES: string[] = [
  '_ga[^=]*',
  '_vis_opt_[^=]*',
  'VWOrandom',
  '_gcl_au',
  '_vwo_uuid_v2',
  '_gid',
  'NID',
];

function isAllAccepted(settings: PrivacySettings): boolean {
  return !Object.values(settings).includes(false);
}

function isSetAccepted(activeSettings: PrivacySettings, requiredSettings: (keyof PrivacySettings)[]): boolean {
  for (const key of requiredSettings) {
    if (activeSettings[key] !== true) {
      return false;
    }
  }

  return true;
}

export default function useMopPrivacy(): UsePrivacy {
  const { $cookie, $urls, $storyblokLivePreview } = useNuxtApp();

  // don't display cookies overlay on initial visit.
  const URL_EXCEPTIONS: string[] = [$urls.IMPRINT, $urls.DATA_PROTECTION];

  function initCookies(): void {
    storeAllCookies(getPrivacySettings());
  }

  function getExpirationTime(settings: PrivacySettings): Date {
    const expirationDays: number = isAllAccepted(settings) ? PRIVACY_LIFESPAN_LONG : PRIVACY_LIFESPAN_SHORT;
    const expirationTime: Date = new Date();
    expirationTime.setDate(expirationTime.getDate() + expirationDays);

    return expirationTime;
  }

  function storePrivacyCookie(settings: PrivacySettings, expires: Date): void {
    const options: CookieOptions = {
      expires,
    };

    $cookie.store(
      COOKIE.PRIVACY,
      JSON.stringify({
        /**
         * “mandatory” flag is deprecated. It has zero information content, because it is (and always has been) true.
         * It’s kept to be on the safe side. Some party could rely on its existence.
         */
        mandatory: true,
        ...settings,
      }),
      options,
    );
  }

  function storeIsInformedAboutCookiesCookie(expires: Date): void {
    const isWildcard: boolean = $cookie.get(COOKIE.ACCEPTED_COOKIE_CONSENT_VERSION) === COOKIE_CONSENT_VERSION_WILDCARD;

    $cookie.store(
      COOKIE.ACCEPTED_COOKIE_CONSENT_VERSION,
      isWildcard ? COOKIE_CONSENT_VERSION_WILDCARD : COOKIE_CONSENT_VERSION.toString(),
      {
        expires,
      },
    );
  }

  function storeAllCookies(settings: PrivacySettings, isInformedAboutCookies?: boolean) {
    const expires: Date = getExpirationTime(settings);

    if (isInformedAboutCookies === undefined) {
      isInformedAboutCookies = !isCookieConsentRequired();
    }

    if (isInformedAboutCookies) {
      storeIsInformedAboutCookiesCookie(expires);
    }

    storePrivacyCookie(settings, expires);
  }

  function getPrivacySettings(): PrivacySettings {
    const privacyCookieValue: string = $cookie.get(COOKIE.PRIVACY) || '{}';
    const defaultSettings: PrivacySettings = {
      marketing: false,
      tracking: false,
      personalization: false,
    };

    try {
      const userSettings: any = hasAcceptedLatestCookieConsent() ? JSON.parse(privacyCookieValue) : {};
      Object.keys(defaultSettings).forEach((key) => {
        if (key in userSettings) {
          // @ts-ignore
          defaultSettings[key] = userSettings[key];
        }
      });
    } catch {
      logWarning(`Cookie value "${privacyCookieValue}" is not a valid JSON.`);
    }

    return defaultSettings;
  }

  function setPrivacySettings(settings: PrivacySettings): boolean {
    const oldPrivacyCookieValue: string = $cookie.get(COOKIE.PRIVACY);

    storeAllCookies(settings, true);

    const hasPrivacyCookieChanged: boolean = $cookie.get(COOKIE.PRIVACY) !== oldPrivacyCookieValue;

    if (hasPrivacyCookieChanged) {
      removeForbiddenCookies();
    }

    return hasPrivacyCookieChanged;
  }

  function removeForbiddenCookies(): void {
    if (!hasMarketingCookiesBeenAccepted()) {
      MARKETING_COOKIES.forEach((pattern) => $cookie.removeByPattern(pattern));
    }

    if (!hasTrackingCookiesBeenAccepted()) {
      TRACKING_COOKIES.forEach((pattern) => $cookie.removeByPattern(pattern));
    }
  }

  function refreshCookiesLifespan() {
    const settings: PrivacySettings = getPrivacySettings();
    if (!isAllAccepted(settings)) {
      return;
    }

    setPrivacySettings(settings);
  }

  function hasMarketingCookiesBeenAccepted(): boolean {
    const settings: PrivacySettings = getPrivacySettings();
    return isSetAccepted(settings, ['marketing']);
  }

  function hasTrackingCookiesBeenAccepted(): boolean {
    const settings: PrivacySettings = getPrivacySettings();
    return isSetAccepted(settings, ['tracking']);
  }

  function isCookieConsentRequiredOnCurrentPage(): boolean {
    return !URL_EXCEPTIONS.some((link) => useRoute().fullPath.includes(link));
  }

  function isCookieConsentRequired(): boolean {
    if ($storyblokLivePreview.isEnabled) {
      return false;
    }

    return !hasAcceptedLatestCookieConsent();
  }

  function hasAcceptedLatestCookieConsent(): boolean {
    return [COOKIE_CONSENT_VERSION.toString(), COOKIE_CONSENT_VERSION_WILDCARD].includes(
      $cookie.get(COOKIE.ACCEPTED_COOKIE_CONSENT_VERSION),
    );
  }

  return securedWrap({
    initCookies,
    getPrivacySettings,
    setPrivacySettings,
    refreshCookiesLifespan,
    isCookieConsentRequiredOnCurrentPage,
    isCookieConsentRequired,
    hasMarketingCookiesBeenAccepted,
    hasTrackingCookiesBeenAccepted,
  });
}
